import { configEnv } from '../config';

const handleResponse = (response: any) => {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
};

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjVlZmJiMWY5MDY5Nzg0MjZjYjBmYzI0ZSIsInVzZXJUeXBlIjoiY2xpZW50IiwidXNlcklkIjoiNTczMDI0NTY4MzAyIiwiZnVsbE5hbWUiOiJOZWlzc2VyIFZpbGxhIiwiZW1haWwiOiJ2aWxsYS5uZWlzc2VyQGdtYWlsLmNvbSIsImNpdHkiOiJCQVEiLCJ1cGRhdGVkQXQiOiIyMDIwLTA5LTE3VDEzOjQ0OjIzLjgzMFoiLCJzaG9wcGVySW4iOltdLCJzdG9yZUlkIjpbXX0sImlhdCI6MTYwMDM1NjUxOX0.IOmt4f3IJdZwIXe013BvMNfEUrVNZEI1T_2tPBqU4iY';

export const registerService = {
    getAvailableStoreId,
    newStore,
    MerchantsExists,
    getAgentByCode
}
/**
 * @param storeId: store id
 */
async function getAvailableStoreId(storeId: string) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(
        `${configEnv.API_URL}/stores/validate?storeId=${storeId}`,
        requestOptions,
    ).then(handleResponse)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
}

/**
 * @param storeId: store id
 */
 async function getAgentByCode(code: string) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(
        `${configEnv.API_URL}/agents/code/${code}`,
        requestOptions,
    ).then(handleResponse)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
}



/**
 * @param storeId: store id
 */
async function MerchantsExists(email: string) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({email}),
        headers: {
            'Authorization':
                token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json',
        },
    };
    return fetch(
        `${configEnv.API_URL}/users/merchants`,
        requestOptions,
    ).then(handleResponse)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
}


/**
 * @param data: store id
 */
async function newStore(dataStore: any) {
    const requestOptions = {
        body: JSON.stringify(dataStore),
        headers: {
            'Authorization':
                token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json',
        },
        method: 'POST',
    };
    return fetch(
        `${configEnv.API_URL}/stores/new`,
        requestOptions,
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
}
